async function main() {
  if (import.meta.env.PROD) {
    import("./logging/utils/initialize");
  }

  const appPromise = import("./App").then(x => x.default);
  const renderPromise = import("./render").then(x => x.default);

  const App = await appPromise;
  const render = await renderPromise;

  render(App);
}

void main();

export {};
